import { render, staticRenderFns } from "./CadastroAgenda.vue?vue&type=template&id=c5b8b4d0&scoped=true&"
import script from "./CadastroAgenda.vue?vue&type=script&lang=js&"
export * from "./CadastroAgenda.vue?vue&type=script&lang=js&"
import style0 from "./CadastroAgenda.vue?vue&type=style&index=0&id=c5b8b4d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b8b4d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
installComponents(component, {VColorPicker})
